<template>
  <v-dialog v-model="dialog" persistent max-width="680">
    <v-card :key="key">
      <v-app-bar flat color="orange">
        <v-toolbar-title class="text-h6 white--text pl-0">
          {{ $t("pending_orders") }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="white" icon @click="$emit('close')" :title="$t('Cancel')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text style="max-height: 60vh; overflow-y: auto;">
        <v-row class="pt-4">
          <v-col cols="12" sm="12">
            <v-list :key="$store.getters.orderChanged" class="grey lighten-4" subheader two-line>
              <template v-if="orders.length > 0">
                <template v-for="(item, index) in orders">
                  <v-list-item :key="item.local_reference" @click="setCurrentOrder(item)">
                    <v-list-item-avatar>
                      <v-icon class="orange lighten-5" color="orange" dark>mdi-clipboard-text</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>

                      <v-list-item-title v-if="item.name_table">
                        {{ $t('table') }}:
                        <v-chip small color="blue" dark>
                          {{ item.name_table }}
                        </v-chip>
                      </v-list-item-title>
                      <v-list-item-title v-if="item.data_seller && item.data_seller.name">
                        {{ $t('Seller') + ': ' + item.data_seller.name }}
                      </v-list-item-title>
                      <!--v-list-item-title v-if="item.data_customer && item.data_customer.name">
                                {{ $t('Customer') + ': ' + item.data_customer.name }}
                              </v-list-item-title-->
                      <v-list-item-title v-if="item.data_domiciliary && item.data_domiciliary.name">
                        {{ $t('Domiciliary') + ': ' + item.data_domiciliary.name }}
                      </v-list-item-title>

                      <v-list-item-subtitle>
                        {{ ($filters.datetime(item.opening_date)) }}
                      </v-list-item-subtitle>

                      <v-list-item-subtitle>
                        {{ $filters.currency(item.total_to_pay, 0) }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn plain elevation="0">

                        <v-icon color="grey lighten-1">mdi-chevron-right</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider v-if="index < orders.length - 1" :key="index"></v-divider>
                </template>
              </template>
              <v-list-item v-if="orders.length == 0 && !loading" >
                <v-list-item-avatar>
                  <v-icon class="red" dark>mdi-close-outline</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('no_pending_orders') }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <!--{{order.data_items}}-->
            </v-list>

          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn dark color="primary" outlined @click="$emit('close')" :title="$t('Cancel')">
          {{ $t("Cancel") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style>
.bg_modal {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)) 0% 0% / cover no-repeat;
}
</style>
<script>

export default {
  components: {  },
  name: "CartPendingOrders",
  data: () => ({
    orders: [],
    key: 0,
    loading: false
  }),
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    async getPendingOrders() {
      this.loading = true;
      this.orders = [];
      this.orders = await this.$pos.getOpenedOrders();
      this.loading = false;
    },
    cancel() {
      this.clear();
      this.searched = false;
      this.key++;
      this.$emit("cancel");
    },
    clear() {
    },
    setCurrentOrder(item) {
      this.$pos.setCurrentOrder(item);
      this.$emit("cancel");
    }
  },
};
</script>